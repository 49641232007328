import { useState  } from "react";
import { useRouter } from "next/router";
import slugify from "@/lib/slugify";
import LoadingSpinner from "@/components/LoadingSpinner";
import * as gtag from "@/lib/gtag";

export default function SearchName({ buttonName = "Find", myPadding = true }) {



  const [form, setForm] = useState("");
  const [searchValue, setSearchValue] = useState("");
 
  const Router = useRouter();
  const enterSearch = (e) => {
    setSearchValue(e);
    searchName();
  }
  const searchName = (e) => {
    //e.preventDefault();
    if (!searchValue) {
      console.log('7' +searchValue)
    //  return;
    } else {
      gtag.event({
        action: "search",
        category: "engagement",
        label: searchValue,
        value: 0,
      });
    setForm("loading");

    Router.push(
      "/cake-with-name/happy-birthday-" + slugify(searchValue) + "-cake"
    ).then(() => window.scrollTo(0, 0)).then(() => setForm("notloading") );
  }};
  // const filteredBlogPosts = posts
  //   .sort(
  //     (a, b) =>
  //       Number(new Date(b.publishedAt)) - Number(new Date(a.publishedAt))
  //   )
  //   .filter((frontMatter) =>
  //     frontMatter.title.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  return (
    <div className="relative w-full mb-4">
      <input
        aria-label="Enter Name to Get Cakes With Name"
        type="text"
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyUp={(e) =>  {
         // console.log(e.key)
          if(e.key === 'Enter'){enterSearch(e.target.value)}
        }}
        placeholder="Enter Name"
        className="px-4 py-2 mt-1 focus:ring-blue-500 border focus:border-blue-500 block w-full border-gray-300 rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
      />
      <button
        className={ "  flex items-center justify-center absolute right-1 top-1 px-4 font-bold h-8 bg-blue-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100 rounded " + (myPadding ? "my-1.5" : "")}
        onClick={searchName}
      >
        {form === "loading" ? <LoadingSpinner /> : " 🔍 " + buttonName}
      </button>
    </div>
  );
}
