import { RWebShare } from "react-web-share";
import * as gtag from "@/lib/gtag";


const Share = () => {
  function gtagEvent(e) {
  
    gtag.event({
      action: "share",
      category:"engagement",
      label: "fab",
      value: 0,
    });
    return true;
  }
  return (
    <div
      className="fixed bottom-0 right-0 w-16 h-16 sm:mb-8 mb-0 sm:mr-12 mr-0 cursor-pointer"
      id="box_btn"  onClick={(e) => gtagEvent(e)} 
    >
      <RWebShare
        data={{
          text: "Try this special cake with your name on Cakeiy.com",
          title: "Special Cake For You",
        }}
      >
        <button aria-label="share birthday cake" className="p-0 w-14 h-14 bg-pink-500 hover:bg-pink-600 rounded-full  active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
          <svg
          id="sharefab"
            fill="none"
            height="34"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="34"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="18" cy="5" r="3" />
            <circle cx="6" cy="12" r="3" />
            <circle cx="18" cy="19" r="3" />
            <line x1="8.59" x2="15.42" y1="13.51" y2="17.49" />
            <line x1="15.41" x2="8.59" y1="6.51" y2="10.49" />
          </svg>
        </button>
      </RWebShare>
    </div>
  );
};

export default Share;
