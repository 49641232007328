import Link from 'next/link';


const ExternalLink = ({ href, children }) => (
  <a
    className="text-gray-500 hover:text-gray-600 transition"
    target="_blank"
    rel="noopener noreferrer"
    href={href}
  >
    {children}
  </a>
);

export default function Footer() {
  return (
    <footer className="flex flex-col justify-center items-start max-w-2xl bg-white dark:bg-black mx-auto w-full mb-8">
      <hr className="w-full border-1 border-gray-200 dark:border-gray-800 mb-8" />
      <div className="w-full max-w-2xl grid grid-cols-1 gap-4 pb-16 sm:grid-cols-3">
        <div className="flex flex-col space-y-4">
          <Link href="/" className="text-gray-500 hover:text-gray-600 transition">
            Home
          </Link>
          <Link href="/about" className="text-gray-500 hover:text-gray-600 transition">
            
              About Cakeiy.com
            
          </Link>
          <Link href="/names" className="text-gray-500 hover:text-gray-600 transition">
            
              Names List
            
          </Link>
        </div>
        <div className="flex flex-col space-y-4">
          <Link
            href="/cake-with-name"
            className="text-gray-500 hover:text-gray-600 transition">
            Birthday cake with name
          </Link>
          <Link
            href="/birthday-cakes-with-year"
            className="text-gray-500 hover:text-gray-600 transition">
            
              Cakes with Year Age
            
          </Link>
          <Link
            href="/cakes-for-relatives-with-name"
            className="text-gray-500 hover:text-gray-600 transition">
            
              Cakes for Relatives
            
          </Link>
        </div>
       
        <div className="flex flex-col space-y-4">
          <Link href="/contact" className="text-gray-500 hover:text-gray-600 transition">
            Contact Us
          </Link>
          <Link href="/policy" className="text-gray-500 hover:text-gray-600 transition">
            
              Privacy Policy
            
          </Link>
          <Link href="/terms" className="text-gray-500 hover:text-gray-600 transition">
            
              Terms and Conditions
            
          </Link>
        </div>
      </div>
    </footer>
  );
}
