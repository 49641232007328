import Link from "next/link";
import Image from 'next/image';

export default function ProjectCard({
  title,
  description,
  href,
  icon
}) {
  return (
    (<Link href={`${href}`} className="mb-4 hover:shadow" aria-label={title}>

      <div className="flex items-center border border-gray-200 dark:border-gray-800 rounded p-4">
        {icon == "react2025" && (
          <div className="w-24 ml-2 mr-4">
            <span className="sr-only">Alphabets</span>
            <Image
        alt={title}
        height={64}
        width={64}
        src={`/category/directory.png`}
        className=""
      />
          </div>
        )}
        <div>
          <h3 className="text-lg font-bold tracking-tight text-gray-900 dark:text-gray-100">
            {title}
          </h3>
          <p className="leading-5 text-gray-700 dark:text-gray-300">
            {description}
          </p>
        </div>
      </div>

    </Link>)
  );
}
