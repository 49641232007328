import Container from "@/components/Container";
import ProjectCard from "@/components/ProjectCard";
import ProjectCardNolink from "@/components/ProjectCardNolink";
import FunctionCard from "@/components/FunctionCard";
import { getAllFilesFrontMatter } from "@/lib/mdx";
import SearchName from "@/components/SearchName";
import { NextSeo, SiteLinksSearchBoxJsonLd } from "next-seo";

//import dynamic from 'next/dynamic'



export default function Home({ category,switzA, germanyA , norwayA }) {

  const sortedCategory = category.sort((a, b) => Number(a.id) - Number(b.id));


  return (
    <Container>
      <NextSeo
        title="Online Cake with Name Generator | Make your Virtual Cake Image"
        description="Just enter name and generate cake images greeting card for free and instant, no signup no waiting instant download the birthday images with name. Edit name, greeting or year and quick download cake image."
        canonical="https://cakeiy.com"
        openGraph={{
          url: "https://cakeiy.com",
          title: "Online Cake with Name Generator| Make your Virtual Cake",
          description:"Just enter name and generate cake images greeting card for free and instant, no signup no waiting instant download the virtual birthday cake with name. Edit name, greeting or year and quick download cake image."
        }}
      />
      <SiteLinksSearchBoxJsonLd
        url="https://cakeiy.com"
        potentialActions={[
          {
            target:
              "https://cakeiy.com/cake-with-name/happy-birthday-{search_term_string}-cake?q",
            queryInput: "search_term_string",
          },
        ]}
      />
      <div className="flex flex-col justify-center items-start max-w-2xl mx-auto mb-16 bg-white dark:bg-black pl-3">
        <h1 className="font-bold text-3xl md:text-5xl tracking-tight mb-4 text-black dark:text-white">
          Virtual Cakes Images With Name
        </h1>
        <p className="text-black dark:text-white mb-16">
          Happy Birthday Cake with Name Images and Greetings to wish on their
          special day. Personalize cake text by writing name, year on it and
          share it on social media. It is a free Virtual cake builder, where you can edit name on cake with years and greetings.  
        </p>
        <h2 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 text-black dark:text-white">
          Search Cakes with Name
        </h2>

        <SearchName />

        <h2 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">
          Categories
        </h2>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 my-2 w-full mt-4">
          {sortedCategory.map((cat) => (
            <FunctionCard
              key={cat.slug}
              title={cat.title}
              slug={cat.target}
              logo={cat.image}
              description={cat.summary}
            />
          ))}
        </div>
        <h2 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">
          Tools
        </h2>
        <ProjectCard
          title="Names"
          description="List of names available on the Cakes Instantly. You can also enter Name on the cake to generate."
          href="/names"
          icon="react2025"
        />
        <h2 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">
          Testimonial
        </h2>

        <ProjectCardNolink
          title="Friends Loves My Personalized Cake Greetings"
          description="Cakeiy.com is an incredible tool. I'm using it for my daily business and personal uses. The team is very creative, keep it up. Now it is easy to wish happy birthday with virtual cake using cakeiy.com.  "
          img="/static/54.jpg"
        />
        <ProjectCardNolink
          title="Clients Likes Cake with their Name"
          description="Since I use most of the designs for online marketing, personal messages the pre-made images provided makes it easy to add a few changes and simply download the final birthday wish images."
          img="/static/91.jpg"
        />
        <ProjectCardNolink
          title="Easy to make birthday wishes for colleagues"
          description="This site helped me a lot, I never fail in impressing my colleagues on their birthday. You can also edit name and greeting. Best site for generating virtual cake birthday greeting card online."
          img="/static/28.jpg"
        />

        
      </div>
    </Container>
  );
}

export async function getStaticProps() {
  const category = await getAllFilesFrontMatter("category");
  

  return { props: { category}  , revalidate: 25920000,};
}
