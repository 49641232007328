import Image from "next/image";

export default function ProjectCard({ title, description, img }) {
  return (
   
      <div className="flex items-center border border-gray-200 dark:border-gray-800 rounded p-4">
        <div className="w-48 ml-2 mr-4 hidden sm:block">
          <span className="sr-only">Reviewer</span>
          <Image alt={`${title}`} height={300} width={300} src={`${img}`} />
        </div>
        <div>
          <h3 className="text-lg font-bold tracking-tight text-gray-900 dark:text-gray-100">
            {title}
          </h3>
          <p className="leading-5 text-gray-700 dark:text-gray-300">
            {description}
          </p>
        </div>
      </div>
  );
}
